<template>
  <div
    id="init"
    v-loading="loading"
    element-loading-text="登录中..."
    :element-loading-spinner="svg"
    element-loading-svg-view-box="-10, -10, 50, 50"
    element-loading-background="rgba(173 ,216, 230, 0.8)"
  ></div>
</template>
<script setup>
import { ref, onMounted } from "vue";
import { FrameApi } from "@/plugins/api.js";
import { useRoute, useRouter } from "vue-router";
import cookie from "@/utils/cookie";
import { ElMessage } from "element-plus";

const loading = ref(true);
const route = useRoute();
const router = useRouter();

function getUserInfoByqy() {
  const params = { code: route.query.code, state: route.query.state };
  FrameApi.getUserInfoByqy(params).then((res) => {
    if (res.Code === 200) {
      ElMessage.success("登录成功！");
      cookie.setCookie("user_name", res.Data.user_name);
      cookie.setCookie("user_tel", res.Data.user_tel);
      cookie.setCookie("user_sex", res.Data.user_sex);
      cookie.setCookie("duty_name", res.Data.duty_name);
      router.push("/");

    } else {
      let msg = res.Message ? res.Message : "获取用户信息失败！";
      ElMessage.error(msg);
      router.push("/login");
    }
  });
}

onMounted(() => {
  if (!route.query.code || !route.query.state) {
    router.push("/login");
  } else {
    getUserInfoByqy();
  }
});
</script>

<style lang="scss" scoped>
#init {
  width: 100vw;
  min-height: calc(100vh - 60px);
  display: flex;
  .right-wp {
    flex: 1;
    width: calc(100vw - 150px);
    background-color: var(--theme-bg-color);
  }
  .right-wp.expand {
    width: calc(100vw - 250px);
  }
}
</style>
